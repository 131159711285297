<template>
  <div class="information">
    <!-- 头部 -->
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan" @click="goback">
            <img src="../../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">{{ $route.meta.title }}</span>
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item>
        <span @click="goback" style="cursor: pointer">监测方案</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 信息详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 外面的大盒子 -->
    <div class="information-box">
      <div class="informaton-main">
        <div class="main-top">
          <div class="main-top-left"></div>
          <!-- 中间部分 -->
          <div class="main-top-center">
            <div class="isEarlyWarnBox">
              <div v-if="textData.isEarlyWarn == 1" class="yujing">已预警</div>
              <p
                v-html="textData.title ? textData.title : ''"
                class="center-title"
              ></p>
            </div>

            <div class="caozuo-center">
              <div
                :class="[
                  'type',
                  textData.tonalState == 2
                    ? 'red'
                    : textData.tonalState == 1
                    ? 'neutral'
                    : 'obverse',
                ]"
              >
                {{
                  textData.tonalState == 2
                    ? "负面"
                    : textData.tonalState == 0
                    ? "正面"
                    : "中性"
                }}
              </div>
              <div v-if="textData.mediaLink != null" class="type website">
                {{ textData.mediaLink }}
              </div>
              <span class="original" @click="lookOriginal(textData.website)">
                <span>
                  <img src="../../assets/img/views/wenzhang.png" alt />
                </span>
                <span>查看原文</span>
              </span>
              <div class="center-timer">
                发布时间:
                <span>{{ textData.releaseTime }}</span>
              </div>
            </div>
            <div class="center-bottom">
              <!-- <span class="center-meiti">
                媒体性质:
                <span>{{textData.mediaLevel}}</span>
                <span style="width:25px;height:20px">
                  <span class="img my-caozuo" @click="myCopy(textData.mediaLevel,1)">
                    <img src="../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </span>-->
              <span class="center-source">
                <span>作者ID:</span>
                <span
                  v-html="textData.authorId ? textData.authorId : '无'"
                ></span>
                <span
                  style="width: 25px; height: 20px"
                  class="img my-caozuo"
                  @click="myCopy(textData.authorId, 3)"
                >
                  <img src="../../assets/img/views/hfz.png" alt />
                </span>
                <span style="margin-left: 40px">来源/作者:</span>
                <span v-html="textData.source ? textData.source : '无'"></span>
                <span>/</span>
                <span v-html="textData.author ? textData.author : '无'"></span>
                <span
                  style="width: 25px; height: 20px"
                  class="img my-caozuo"
                  @click="myCopy(textData.source + '/' + textData.author, 2)"
                >
                  <img src="../../assets/img/views/hfz.png" alt />
                </span>
              </span>
              <!-- 	<span class="center-fenlei">
								事件分类:
								<span>{{ textData.eventClass}}</span>
								<el-tooltip class="item" effect="dark"
									:content="textData.eventClass?textData.eventClass:'暂无事件'" placement="top">
									<span class="gengduo">(更多)</span>
								</el-tooltip>
								<span style="width:25px;height:20px">
									<span class="img my-caozuo" @click="myCopy(textData.eventClass,3)">
										<img src="../../assets/img/views/hfz.png" alt />
									</span>
								</span>
              </span>-->
              <div class="center-key-box">
                <div class="center-key">
                  关键字:
                  <div v-if="textData.keywordList">
                    <span
                      v-for="(ite, indx) in textData.keywordList"
                      :class="[
                        ite !=
                        textData.keywordList[textData.keywordList.length - 1]
                          ? 'styleMargin'
                          : '',
                      ]"
                      :key="indx"
                      >{{ ite }}</span
                    >
                  </div>
                  <span v-else>无</span>
                </div>
                <div
                  v-if="
                    textData.keyword &&
                    textData.keywordArry &&
                    textData.keywordArry.length > 10
                  "
                  class="gengduo-link-box"
                >
                  <el-tooltip class="item" effect="dark" placement="top">
                    <div slot="content" v-html="textData.keyword"></div>
                    <span class="gengduo-link">(更多)</span>
                  </el-tooltip>
                </div>
                <span
                  style="
                    width: 25px;
                    height: 20px;
                    margin-left: 5px;
                    margin-top: 3px;
                  "
                >
                  <span
                    class="img my-caozuo"
                    @click="myCopy(textData.keyword, 4)"
                  >
                    <img src="../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="main-top-right"></div>
        </div>
        <div style="padding: 32px 210px 0 210px; min-height: 430px">
          <p
            v-html="item ? item : ''"
            class="main-text"
            v-for="(item, index) in textData.contentList"
            :key="index"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectDataById } from "../../api/monitoringScheme/index";
export default {
  data() {
    return {
      textData: {},
      dataId: this.$route.query.dataId,
      monitorId: this.$route.query.monitorId,
      projectType: "0",
      rank: this.$route.query.rank,
    };
  },
  methods: {
    // 返回上一页
    goback() {
      this.$router.push("/monitoringScheme/index/Territory");
    },
    async getInfo() {
      let data = {
        dataId: this.dataId,
        projectType: "0",
        page: this.page,
        rank: this.rank,
        monitorId: this.monitorId,
      };
      let res = await selectDataById(data);
      if (res.data.data) {
        this.textData = res.data.data;
        if (this.textData.keyword) {
          this.textData.keywordList = this.textData.keyword.split(" ");
          this.textData.keywordArry = this.textData.keyword.split(" ");
          if (this.textData.keywordList.length > 10) {
            this.textData.keywordList = this.textData.keywordList.slice(0, 10);
          }
        }
        if (this.textData.content) {
          this.textData.contentList = this.textData.content.split("\n");
        }
        if (this.textData.keyword) {
          let reg = new RegExp(" ", "g"); //g代表全部
          this.textData.keyword = this.textData.keyword.replace(reg, "<br/>");
        }
        // this.heightText();
      }
    },
    // 关键字高亮
    // heightText() {
    //   let word = this.textData.keyword.split("<br/>");
    //   let lineFeed = new RegExp("\\n", "g");
    //   if (this.textData.content) {
    //     this.textData.content = this.textData.content.replace(
    //       lineFeed,
    //       "<br/>"
    //     );
    //   }

    //   word.forEach(ite => {
    //     let reg = new RegExp(ite, "g"); //g代表全部
    //     if (this.textData.title != null) {
    //       this.textData.title = this.textData.title.replace(
    //         reg,
    //         '<span style="color:red">' + ite + "</span>"
    //       );
    //     }
    //     if (this.textData.contentList) {
    //       for (let i = 0; i < this.textData.contentList.length; i++) {
    //         this.textData.contentList[i] = this.textData.contentList[i].replace(
    //           reg,
    //           '<span style="color:red">' + ite + "</span>"
    //         );
    //       }
    //     }
    //   });
    // },
    // 个别复制
    myCopy(msg, type) {
      msg = msg ? msg : "无";
      let str = "";
      if (type == 1) {
        str = `媒体性质:${msg}`;
      } else if (type == 2) {
        str = `来源/作者:${msg}`;
      } else if (type == 3) {
        str = `作者ID:${msg}`;
      } else {
        str = `关键字:${msg}`;
      }
      this.$publicFun.copyFun(str);
    },
    lookOriginal(web) {
      window.open(web);
    },
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataId = params.dataId;
      this.monitorId = params.monitorId;
      this.rank = params.rank;
    }
    this.getInfo();
  },
};
</script>
<style scoped>
.information-haeder {
  justify-content: space-between;
  display: flex;
  padding: 10px 24px;
  width: 100%;
  height: 56px;
  background: #ffffff;
}

.gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.center-key-box {
  display: flex;
  align-items: center;
}

.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}

.quan {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #cccccc;
  border-radius: 50%;
}

.xiangq {
  margin-top: 4px;
  margin-left: 16px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.information-box {
  padding: 0 24px 24px 24px;
}

.informaton-main {
  padding: 35px 32px 28px 35px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
}

.main-top {
  position: relative;
  padding: 40px 210px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e6ec;
}

.main-top-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 37px;
  height: 55px;
  background: url("../../assets/img/views/beijing.png");
  background-size: 37px 55px;
}

.styleMargin {
  margin-right: 5px;
}

.main-top-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 166px;
  height: 180px;
  background: url("../../assets/img/views/beijing.png");
}

.center-title {
  max-width: 1066px;
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}

.type {
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  padding: 0 5px;
  height: 24px;
  margin-right: 14px;
}

.website {
  /* margin-left: 16px; */
  background-color: #868bff;
}

.original {
  display: flex;
  margin-left: 16px;
  cursor: pointer;
  font-size: 14px;
  color: #2e59ec;
}

.original span:nth-child(1) {
  margin-top: 2px;
  margin-right: 5px;
}

.center-timer {
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
  font-size: 14px;
  color: #999999;
}

.center-meiti,
.center-source,
.center-fenlei {
  position: relative;
  display: flex;
  font-size: 14px;
  color: #333;
}

.center-source {
  display: flex;
  align-items: center;
}

.center-fenlei {
  margin-left: 40px;
}

.center-source .img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-meiti .img,
.center-fenlei .img {
  position: absolute;
  right: 0;
  top: 7px;
}

.center-bottom {
  display: flex;
  align-items: center;
  height: 35px;
  margin-top: 16px;
}

.gengduo {
  padding-left: 8px;
  width: 46px;
  height: 20px;
  cursor: pointer;
  color: #2e59ec;
  font-size: 14px;
}

.center-key {
  display: flex;
  font-size: 14px;
  color: #333;
  margin-left: 40px;
}

.main-text {
  line-height: 26px;
  text-indent: 2em;
  font-size: 14px;
  color: #333333;
}

.caozuo-center {
  line-height: 24px;
  margin-top: 24px;
  display: flex;
}

.yujing {
  margin-left: 0;
  width: 62px;
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
  padding: 0 8px;
  background: #ffedef;
  font-size: 14px;
  font-weight: bold;
  color: #ea3342;
}

.isEarlyWarnBox {
  display: flex;
  align-items: center;
}
</style>
